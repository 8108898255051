import { Observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ObjectDisplayer from 'components/shared/ObjectDisplayer'
import useApiCallStore from 'hooks/useApiCallStore'

import Link from '../Link'

const TaxYearSummary = () => {
  const { taxCalculationId, year } = useParams() as unknown as {
    taxCalculationId: string
    year: number
  }
  const store = useApiCallStore()

  return (
    <Observer>
      {() => {
        const [loading, setLoading] = useState(true)

        const { taxYearSummary } = store

        useEffect(() => {
          store
            .getTaxYearSummary({ taxCalculationId, year })
            .catch(console.warn)
            .finally(() => setLoading(false))
        }, [])

        if (loading) return null

        if (!taxYearSummary) return <div>No tax calculation summary found.</div>

        return (
          <div>
            <h4>{year} Tax Year Summary</h4>
            <ObjectDisplayer object={taxYearSummary} />
            <Link to={`/api_testing/tax_calculations/${taxCalculationId}/years/${year}/events`}>
              Taxable Events
            </Link>
          </div>
        )
      }}
    </Observer>
  )
}

export default TaxYearSummary
