import React from 'react'

import styles from './index.module.scss'

const Toggle = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange: (checked: boolean) => void
}) => {
  const id = window.crypto.randomUUID()

  return (
    <div className={styles.toggleSwitch}>
      <input
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        className={styles.toggleSwitchCheckbox}
        id={id}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.toggleSwitchLabel} htmlFor={id}>
        <span className={styles.toggleSwitchInner} />
        <span className={styles.toggleSwitchSwitch} />
      </label>
    </div>
  )
}

export default Toggle
