import React from 'react'
import ReactPaginate from 'react-paginate'
import './paginate.scss'

const Pagination = ({
  pageCount,
  page,
  onPageChange,
}: {
  pageCount?: number
  page?: number
  onPageChange?: (_: number) => void
}) => (
  <ReactPaginate
    pageCount={pageCount || 1}
    forcePage={(page || 1) - 1}
    onPageChange={({ selected }) => onPageChange?.(selected + 1)}
    pageClassName="page-item"
    pageLinkClassName="page-link"
    previousClassName="page-item"
    previousLinkClassName="page-link"
    nextClassName="page-item"
    nextLinkClassName="page-link"
    breakLabel="..."
    breakClassName="page-item"
    breakLinkClassName="page-link"
    containerClassName="pagination"
    activeClassName="active"
  />
)

export default Pagination
