import { Observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import DataGrid, { Column } from 'react-data-grid'
import { useParams } from 'react-router-dom'

import { TaxableEvent } from 'api/zlApi/types'
import Pagination from 'components/shared/Pagination'
import useApiCallStore from 'hooks/useApiCallStore'

import styles from './index.module.scss'

const TaxYearEvents = () => {
  const { taxCalculationId, year } = useParams() as unknown as {
    taxCalculationId: string
    year: number
  }
  const store = useApiCallStore()

  useEffect(() => {
    store.getTaxableEvents({ taxCalculationId, year, page: 1 }).catch(console.warn)
  }, [])

  return (
    <Observer>
      {() => {
        const columns: Column<TaxableEvent>[] = [
          { key: 'digest', name: 'Digest' },
          { key: 'buy_date', name: 'Bought on' },
          { key: 'sell_date', name: 'Sold on' },
          { key: 'currency', name: 'Currency' },
          { key: 'quantity_sold', name: 'Quantity sold' },
          { key: 'proceeds', name: 'Proceeds' },
          { key: 'cost_basis', name: 'Cost basis' },
        ]
        const rows = store.taxableEvents
        const pageCount = store.taxableEventsMetadata
          ? Math.ceil(
              store.taxableEventsMetadata.total_count / store.taxableEventsMetadata.page_size,
            )
          : 1
        return (
          <div className={styles.container}>
            <h4>{year} Taxable Events</h4>
            <DataGrid
              className={`rdg-light ${styles.table}`}
              columns={columns}
              rows={rows}
              defaultColumnOptions={{ resizable: true }}
            />
            <Pagination
              onPageChange={(page) => store.getTaxableEvents({ taxCalculationId, year, page })}
              page={store.taxableEventsMetadata?.page}
              pageCount={pageCount}
            />
          </div>
        )
      }}
    </Observer>
  )
}

export default TaxYearEvents
