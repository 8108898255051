import React from 'react'

const ObjectDisplayer = <T,>({
  object,
  level = 0,
  roundNumbersTo = 10,
}: {
  object: T
  level?: number
  roundNumbersTo?: number
}) => {
  if (object === undefined || object === null) return null

  if (Array.isArray(object)) {
    return (
      <ul>
        {object.map((obj, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <ObjectDisplayer key={i.toString()} object={obj} level={level + 1} />
        ))}
      </ul>
    )
  }

  if (typeof object === 'object') {
    return (
      <ul>
        {Object.entries(object as object).map(([key, value]) => (
          <li key={key}>
            {key}: <ObjectDisplayer object={value} level={level + 1} />
          </li>
        ))}
      </ul>
    )
  }

  let value = `${object}`
  if (value.match(/\d+\.\d{10,}/)) {
    value = `${parseFloat(value).toFixed(roundNumbersTo)}`
  }
  return level === 0 ? <ul>{`${value}`}</ul> : `${value}`
}

export default ObjectDisplayer
