import React, { useMemo } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'

import LatestTaxCalculation from 'components/ApiTesting/LatestTaxCalculation'
import Users from 'components/ApiTesting/Users'
import Layout from 'components/Layout'
import ApiCallStoreContext from 'contexts/ApiCallStoreContext'
import ApiCallStore from 'stores/ApiCallStore'

import TaxYearEvents from '../../components/ApiTesting/TaxYearEvents'
import TaxYearSummary from '../../components/ApiTesting/TaxYearSummary'

import styles from './index.module.scss'

const ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/api_testing/users" />,
  },
  {
    path: 'users',
    element: <Users />,
  },
  {
    path: 'users/:userId/latestTaxCalculation',
    element: <LatestTaxCalculation />,
  },
  {
    path: 'tax_calculations/:taxCalculationId/years/:year/summary',
    element: <TaxYearSummary />,
  },
  {
    path: 'tax_calculations/:taxCalculationId/years/:year/events',
    element: <TaxYearEvents />,
  },
]

const ApiTesting = () => {
  const store = useMemo(() => new ApiCallStore(), [])

  return (
    <ApiCallStoreContext.Provider value={store}>
      <Layout>
        <div className={styles.apiContainer}>{useRoutes(ROUTES)}</div>
      </Layout>
    </ApiCallStoreContext.Provider>
  )
}

export default ApiTesting
