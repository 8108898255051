import React from 'react'

import ObjectDisplayer from 'components/shared/ObjectDisplayer'
import { IframeMessage } from 'stores/IframeMessageStore'

const IframeMessageLogItem = ({
  message: {
    data: { eventType, data },
  },
}: {
  message: IframeMessage
}) => (
  <div>
    <ul>
      <li>
        <div>{eventType}</div>
        {Object.entries(data).map(([key, value]) => (
          <ObjectDisplayer key={key} object={{ [key]: value }} />
        ))}
      </li>
    </ul>
  </div>
)

export default IframeMessageLogItem
