import axios, { AxiosRequestHeaders, AxiosRequestTransformer } from 'axios'

import env from 'env'

import {
  JwtTokenResponse,
  LatestTaxCalculationParams,
  LatestTaxCalculationResponse,
  PaginationParams,
  TaxableEventsParams,
  TaxableEventsResponse,
  TaxYearSummaryParams,
  TaxYearSummaryResponse,
  UsersResponse,
} from './types'

const { CLIENT_ID, CLIENT_SECRET } = env
const BASE_URL = env.IFRAME_URL
const V1_BASE_URL = `${BASE_URL}/partners/api/v1`

let jwtToken: string | null = null
let tokenExpiresAt: Date | null = null

const apiService = axios.create({
  transformRequest: [
    (data: unknown, headers: AxiosRequestHeaders) => {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${jwtToken}`
      return data
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
})

const getJwtToken = async (): Promise<JwtTokenResponse> => {
  const body = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    grant_type: 'client_credentials',
  }
  const { data } = await axios.post<JwtTokenResponse>(`${BASE_URL}/oauth/token`, body)
  return data
}

const wrapApiCall = <
  T extends (...args: never[]) => Promise<unknown>,
  U extends Awaited<ReturnType<T>>,
  V extends (...args: Parameters<T>) => Promise<U>,
>(
  func: T,
): V => {
  const wrapped = async (...args: Parameters<T>): Promise<U> => {
    if (!jwtToken || (tokenExpiresAt && tokenExpiresAt < new Date())) {
      const now = new Date()
      const { access_token: accessToken, expires_in: expiresIn } = await getJwtToken()
      jwtToken = accessToken
      now.setSeconds(now.getSeconds() + (expiresIn * 2) / 3)
      tokenExpiresAt = now
    }

    return (await func(...args)) as U
  }

  return wrapped as V
}

export const getUsers = wrapApiCall(async (params: PaginationParams): Promise<UsersResponse> => {
  const { data } = await apiService.get<UsersResponse>(`${V1_BASE_URL}/users`, { params })
  return data
})

export const getLatestTaxCalculation = wrapApiCall(
  async ({ userId }: LatestTaxCalculationParams) => {
    const {
      data: { tax_calculation: taxCalculation },
    } = await apiService.get<LatestTaxCalculationResponse>(
      `${V1_BASE_URL}/users/${userId}/user_tax_calculations/latest`,
    )
    return taxCalculation
  },
)

export const getTaxYearSummary = wrapApiCall(
  async ({ taxCalculationId, year }: TaxYearSummaryParams) => {
    const {
      data: { tax_year_summary: taxYearSummary },
    } = await apiService.get<TaxYearSummaryResponse>(
      `${V1_BASE_URL}/tax_calculations/${taxCalculationId}/years/${year}/summary`,
    )
    return taxYearSummary
  },
)

export const getTaxableEvents = wrapApiCall(
  async ({ taxCalculationId, year, ...params }: TaxableEventsParams) => {
    const { data } = await apiService.get<TaxableEventsResponse>(
      `${V1_BASE_URL}/tax_calculations/${taxCalculationId}/years/${year}/events`,
      { params },
    )
    return data
  },
)
