import React, { ReactNode } from 'react'
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom'

import styles from './Link.module.scss'

const Link = ({
  to,
  children,
  className,
  activeClassName,
}: {
  to: string
  children: ReactNode
  className?: string
  activeClassName?: string
}) => {
  const location = useLocation()
  const pathRegexp = new RegExp(`^/${location.pathname.split('/')[1]}(/.+)?$`)

  const classNames = [className || styles.link]
  if (to.match(pathRegexp)) classNames.push(activeClassName || styles.active)

  return (
    <ReactRouterDomLink to={to} className={classNames.join(' ')}>
      {children}
    </ReactRouterDomLink>
  )
}

export default Link
