import { Observer } from 'mobx-react-lite'
import React, { useEffect, useRef } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useNavigate } from 'react-router-dom'

import paths from 'constants/paths'
import useIframeMessageStore from 'hooks/useIframeMessageStore'
import useSettingsStore from 'hooks/useSettingsStore'

const MessageWatcher = () => {
  const searchedMessages = useRef<{ [_: string]: boolean }>({})
  const store = useIframeMessageStore()
  const { settings } = useSettingsStore()

  store.clearMessages()

  return (
    <Observer>
      {() => {
        const messages = store.messages || []
        const finishedEvents = useRef<{ [_: string]: boolean }>({})
        const navigate = useNavigate()
        const { autoDetectWhenTaxesAreDone } = settings

        useEffect(() => {
          if (!autoDetectWhenTaxesAreDone) {
            finishedEvents.current = {}
            return
          }

          messages.forEach(({ uuid, data: { eventType } }) => {
            if (searchedMessages.current[uuid]) return
            if (!eventType.match(/^taxes\./)) return

            finishedEvents.current[eventType] = true
          })
          if (Object.keys(finishedEvents.current).length === 3) {
            confirmAlert({
              title: 'Confirm to continue',
              message: 'Are you finished with your taxes?',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    navigate(paths.iframeMessageLog)
                  },
                },
                {
                  label: 'No',
                },
              ],
              willUnmount: () => {
                messages.forEach(({ uuid }) => {
                  searchedMessages.current[uuid] = true
                })
              },
            })
            messages.forEach(({ uuid }) => {
              searchedMessages.current[uuid] = true
            })
            finishedEvents.current = {}
          }
        }, [messages.length, autoDetectWhenTaxesAreDone])

        return null
      }}
    </Observer>
  )
}

export default MessageWatcher
