import { makeObservable, observable, runInAction } from 'mobx'

import { getLatestTaxCalculation, getTaxableEvents, getTaxYearSummary, getUsers } from 'api/zlApi'
import {
  Gains,
  PaginatedResponse,
  TaxableEvent,
  TaxableEventsParams,
  TaxCalculation,
  TaxYearSummaryParams,
  User,
} from 'api/zlApi/types'

class ApiCallStore {
  users: User[] = []

  usersMetadata: PaginatedResponse['meta'] | null = null

  latestTaxCalculation: TaxCalculation | null = null

  taxYearSummary: Gains | null = null

  taxableEvents: TaxableEvent[] = []

  taxableEventsMetadata: PaginatedResponse['meta'] | null = null

  constructor() {
    makeObservable(this, {
      users: observable,
      usersMetadata: observable,
      latestTaxCalculation: observable,
      taxYearSummary: observable,
      taxableEvents: observable,
      taxableEventsMetadata: observable,
    })
  }

  async getUsers({ page = 1 }) {
    try {
      const { users, meta } = await getUsers({ page })
      runInAction(() => {
        this.users = users
        this.usersMetadata = meta
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async getLatestTaxCalculation(userId: string) {
    try {
      const taxCalculation = await getLatestTaxCalculation({ userId })
      runInAction(() => {
        this.latestTaxCalculation = taxCalculation
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async getTaxYearSummary(params: TaxYearSummaryParams) {
    try {
      const taxYearSummary = await getTaxYearSummary(params)
      runInAction(() => {
        this.taxYearSummary = taxYearSummary
      })
    } catch (error) {
      console.warn(error)
    }
  }

  async getTaxableEvents(params: TaxableEventsParams) {
    try {
      const { taxable_events: taxableEvents, meta } = await getTaxableEvents(params)
      runInAction(() => {
        this.taxableEvents = taxableEvents
        this.taxableEventsMetadata = meta
      })
    } catch (error) {
      console.warn(error)
    }
  }
}

export default ApiCallStore
