import React from 'react'

import Layout from 'components/Layout'

import styles from './index.module.scss'
import SettingToggle from './SettingToggle'

const Settings = () => (
  <Layout>
    <div className={styles.container}>
      <h2>Settings</h2>
      <SettingToggle setting="autoDetectWhenTaxesAreDone" title="Auto detect when taxes are done" />
    </div>
  </Layout>
)

export default Settings
