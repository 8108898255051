import { Observer } from 'mobx-react-lite'
import React from 'react'

import Toggle from 'components/shared/Toggle'
import 'react-toggle/style.css'
import useSettingsStore from 'hooks/useSettingsStore'
import SettingsStore from 'stores/SettingsStore'

import styles from './SettingToggle.module.scss'

const SettingToggle = ({
  setting,
  title,
}: {
  setting: keyof typeof SettingsStore.prototype.settings
  title: string
}) => {
  const settingsStore = useSettingsStore()
  const { settings } = settingsStore

  return (
    <Observer>
      {() => (
        <div className={styles.settingToggle}>
          <Toggle checked={settings[setting]} onChange={() => settingsStore.toggle(setting)} />
          <span className={styles.label}>{title}</span>
        </div>
      )}
    </Observer>
  )
}

export default SettingToggle
