import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'

import env from 'env'

import styles from './index.module.scss'
import MessageWatcher from './MessageWatcher'

const ZenlegderIFrame = () => {
  const src = `${env.IFRAME_URL}/auth/saml/sign_in?idp_id=https%3A%2F%2Fsaml.example.com%2Fentityid`

  return (
    <>
      <MessageWatcher />
      <iframe className={styles.contentIframe} title="ZenLedger" src={src} />
    </>
  )
}

export default ZenlegderIFrame
