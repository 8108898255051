import { Observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import DataGrid, { Column, FormatterProps } from 'react-data-grid'

import { User } from 'api/zlApi/types'
import Pagination from 'components/shared/Pagination'
import useApiCallStore from 'hooks/useApiCallStore'

import Link from '../Link'

import styles from './index.module.scss'

const TaxCalcLink = ({ row }: FormatterProps<User>) => (
  <Link to={`/api_testing/users/${row.id}/latestTaxCalculation`}>Tax Calculation</Link>
)

const Users = () => {
  const store = useApiCallStore()

  useEffect(() => {
    store.getUsers({}).catch(console.warn)
  }, [])

  return (
    <Observer>
      {() => {
        const columns: Column<User, unknown>[] = [
          { key: 'id', name: 'ID' },
          { key: 'email', name: 'Email' },
          { key: 'time_zone', name: 'Time zone' },
          { key: 'created_at', name: 'Created At' },
          { key: 'updated_at', name: 'Updated At' },
          {
            key: 'action',
            name: 'Tax Calculation',
            formatter: TaxCalcLink,
          },
        ]
        const rows = store.users
        const pageCount = store.usersMetadata
          ? Math.ceil(store.usersMetadata.total_count / store.usersMetadata.page_size)
          : 1
        return (
          <div className={styles.container}>
            <h4>Users</h4>
            <DataGrid
              className={`rdg-light ${styles.table}`}
              columns={columns}
              rows={rows}
              defaultColumnOptions={{ resizable: true }}
            />
            <Pagination
              page={store.usersMetadata?.page}
              pageCount={pageCount}
              onPageChange={(page) => store.getUsers({ page })}
            />
          </div>
        )
      }}
    </Observer>
  )
}

export default Users
