import React, { ReactNode } from 'react'

import styles from './index.module.scss'
import Sidebar from './Sidebar'

const Layout = ({ children }: { children: ReactNode }) => (
  <div className={styles.layout}>
    <div className={styles.contentLayout}>
      <Sidebar />
      <div className={styles.content}>{children}</div>
    </div>
  </div>
)

export default Layout
