import { makeObservable, observable, runInAction } from 'mobx'

class SettingsStore {
  settings = {
    autoDetectWhenTaxesAreDone: false,
  }

  constructor() {
    const settingsString = localStorage.getItem('settings')
    if (settingsString) {
      this.settings = JSON.parse(settingsString)
    }
    makeObservable(this, { settings: observable })
  }

  toggle<T extends keyof typeof this.settings>(setting: T) {
    runInAction(() => {
      this.settings[setting] = !this.settings[setting]
      localStorage.setItem('settings', JSON.stringify(this.settings))
    })
  }
}

export default SettingsStore
