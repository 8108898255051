import React, { ReactNode } from 'react'
import { Link as ReactRouterDomLink } from 'react-router-dom'

import styles from './index.module.scss'

const Link = ({ to, children }: { to: string; children: ReactNode }) => (
  <ReactRouterDomLink to={to} className={styles.link}>
    {children}
  </ReactRouterDomLink>
)

export default Link
